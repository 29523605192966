var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isTipEnabled)?_c('div',[_c('div',{staticClass:"text-subtitle-1 primary--text font-weight-bold"},[_vm._v("Propina")]),_vm._l((_vm.tipOptions),function(tip){return _c('v-btn',{key:tip,staticClass:"mr-2 px-3 my-2 accent--text font-weight-regular text-body-2 py-0",class:tip === _vm.currentTip.amount ? 'no-active white--text' : '',attrs:{"disabled":!_vm.isEnabled,"color":"secondary","depressed":"","height":"31","min-width":10,"rounded":""},on:{"click":function($event){return _vm.onClickTip(tip)}}},[_c('span',{staticClass:"text-none",class:!_vm.isEnabled && tip === _vm.currentTip.amount
          ? 'primary--text font-weight-medium'
          : ''},[_vm._v(" $"+_vm._s(tip)+" ")])])}),_c('v-btn',{staticClass:"mr-2 px-3 accent--text font-weight-regular text-body-2 py-0",class:_vm.isOtherTip ? 'no-active white--text' : '',attrs:{"disabled":!_vm.isEnabled,"color":"secondary","depressed":"","height":"31","min-width":10,"rounded":""},on:{"click":function($event){_vm.isDelivery
        ? _vm.$router.push({
            path: '/menu/weborders/payment/tip',
            query: _vm.$route.query,
          })
        : _vm.$router.push({
            path: '/menu/order/details/payment/tip',
            query: _vm.$route.query,
          })}}},[_c('span',{staticClass:"text-none"},[_vm._v(" Otro ")])])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }