<template>
  <v-container
    v-if="hasToShowTipSelector"
    class="px-4"
    id="menu-order-details-payment-tip-selector"
  >
    <tip-selector
      :is-delivery="$route.meta.isDelivery ? true : false"
      v-if="!isOrderEmpty && !isOrderOpenAndHasNoProductsYet"
    />
  </v-container>
</template>

<script>
import TipSelector from "@/components/menu/order/payment/TipSelector";
import thankYouCommon from "@/mixins/firebase/thankYouCommon";

export default {
  name: "MenuOrderDetailsTipSelector",
  mixins: [thankYouCommon],
  provide: {
    heading: { align: "start" },
  },

  components: {
    TipSelector,
  },

  computed: {
    hasToShowTipSelector() {
      return (
        this.currentTip.status === this.paymentStatus[0] ||
        this.currentTip.status === this.paymentStatus[1]
      );
    },
    paymentStatus() {
      return this.$store.getters["order/paymentStatus"];
    },
    currentTip() {
      return this.$store.state.order.currentTip;
    },
  },
};
</script>
