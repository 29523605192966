<template>
  <div v-if="isTipEnabled">
    <div class="text-subtitle-1 primary--text font-weight-bold">Propina</div>
    <v-btn
      v-for="tip in tipOptions"
      :disabled="!isEnabled"
      :key="tip"
      @click="onClickTip(tip)"
      color="secondary"
      class="mr-2 px-3 my-2 accent--text font-weight-regular text-body-2 py-0"
      :class="tip === currentTip.amount ? 'no-active white--text' : ''"
      depressed
      height="31"
      :min-width="10"
      rounded
    >
      <span
        class="text-none"
        :class="
          !isEnabled && tip === currentTip.amount
            ? 'primary--text font-weight-medium'
            : ''
        "
      >
        ${{ tip }}
      </span>
    </v-btn>
    <v-btn
      @click="
        isDelivery
          ? $router.push({
              path: '/menu/weborders/payment/tip',
              query: $route.query,
            })
          : $router.push({
              path: '/menu/order/details/payment/tip',
              query: $route.query,
            })
      "
      :disabled="!isEnabled"
      :class="isOtherTip ? 'no-active white--text' : ''"
      color="secondary"
      class="mr-2 px-3 accent--text font-weight-regular text-body-2 py-0"
      depressed
      height="31"
      :min-width="10"
      rounded
    >
      <span class="text-none"> Otro </span>
    </v-btn>
  </div>
</template>
<script>
import thankYouCommon from "@/mixins/firebase/thankYouCommon";
export default {
  props: {
    isDelivery: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [thankYouCommon],
  computed: {
    isTipEnabled() {
      return this.$store.state.menu.profile.isTipEnabled;
    },
    currentTip() {
      if (this.isDelivery) {
        return this.$store.state.deliveryCart.currentTip;
      }
      return this.$store.state.order.currentTip;
    },
    tipOptions() {
      if (this.isDelivery) {
        return this.$store.state.deliveryCart.tipOptions;
      }
      return this.$store.state.order.tipOptions;
    },
    isOtherTip() {
      if (this.tipOptions.has(this.currentTip.amount)) {
        return false;
      }
      return true;
    },
    isEnabled() {
      if (this.isDelivery) {
        return true;
      }

      return (
        this.currentTip.status ===
          this.$store.getters["order/paymentStatus"][0] && !this.getOrderUuid
      );
    },
  },
  methods: {
    onClickTip(tip) {
      this.isDelivery
        ? this.$store.dispatch("deliveryCart/addNewCurrentTip", tip)
        : this.$store.dispatch("order/addNewCurrentTip", tip);
    },
  },
};
</script>
<style lang="sass" scoped>
::v-deep .no-active
  background-color:  var(--v-primary-base) !important
  opacity: 1 !important
  > span
    color: white
</style>
